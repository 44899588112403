<template>
  <RevForm
    :id="id"
    class="mt-12"
    :initial-values="initialValues"
    :name="id"
    :validate="validate"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <div class="flex flex-col gap-12">
        <RevInputText
          id="lastName"
          v-model="values.lastName"
          autocomplete="family-name"
          data-cs-mask
          :error="errors.lastName"
          :label="i18n(translations.lastNameLabel)"
        />
        <RevInputText
          id="firstName"
          v-model="values.firstName"
          autocomplete="given-name"
          data-cs-mask
          :error="errors.firstName"
          :label="i18n(translations.firstNameLabel)"
        />
        <RevInputText
          id="phone"
          v-model="values.phone"
          data-cs-mask
          :error="errors.phone"
          :format="formatPhone"
          :label="i18n(translations.phoneLabel)"
        />
        <RevInputText
          id="email"
          v-model="values.email"
          autocomplete="email"
          data-cs-mask
          :error="errors.email"
          :icon="IconMail"
          :label="i18n(translations.emailLabel)"
          type="email"
        />
      </div>
    </template>
  </RevForm>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import {
  RevForm,
  makeValidate,
  matchingRegExp,
  missingRegExp,
  required,
} from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'
import { IconMail } from '@ds/icons/IconMail'
import type { InputValue } from '@ds/types/InputValue'

import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterProps,
  PaymentSubmitError,
  type SubmitSuccessData,
} from '../../../form-common/types'

import translations from './AdyenKonbiniAdapter.translations'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const i18n = useI18n()

type Values = {
  firstName: string
  lastName: string
  phone: string
  email: string
}

const handleSubmit = async ({ firstName, lastName, phone, email }: Values) => {
  emit('submit-start')
  try {
    const result = await props.createPayment({
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      email,
    })

    emit('submit-success', result as SubmitSuccessData)
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}

const REGEX_VALID_KONBINI_PHONE = /^\d{10,11}$/
const REGEXP_VALID_EMAIL = /^[^\s@]+@[^\s@]+$/
const REGEX_INVALID_KONBINI_EMAIL = /\+/
const formatPhone = (value: InputValue) => {
  if (!value || typeof value !== 'string') {
    return value
  }

  const REGEX_INVALID_CHAR_KONBINI_PHONE = /\D/g

  return value.replace(REGEX_INVALID_CHAR_KONBINI_PHONE, '')
}

const { user } = useUserStore()

const initialValues = computed(
  (): Values => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phone: String(formatPhone(user?.phone?.phone ?? '') ?? ''),
    email: user?.email ?? '',
  }),
)

const validate = makeValidate<Values>({
  lastName: {
    REQUIRED: required(i18n(translations.lastNameRequired)),
  },
  firstName: {
    REQUIRED: required(i18n(translations.firstNameRequired)),
  },
  phone: {
    REQUIRED: required(i18n(translations.phoneRequired)),
    VALID: matchingRegExp(
      REGEX_VALID_KONBINI_PHONE,
      i18n(translations.phoneInvalid),
    ),
  },
  email: {
    REQUIRED: required(i18n(translations.emailRequired)),
    VALID: matchingRegExp(REGEXP_VALID_EMAIL, i18n(translations.emailInvalid)),
    KONBINI_VALID: missingRegExp(
      REGEX_INVALID_KONBINI_EMAIL,
      i18n(translations.emailKonbiniInvalid),
    ),
  },
})
</script>
